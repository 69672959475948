export const apiRouter = {
  LOG_IN: "/admin/login",
  LOG_OUT: "/admin/logout",
  GET_USER_LIST: "admin/userList",
  GET_MENUS: "/admin/getMenus",
  ADD_MENU: "/admin/addMenu",
  UPDATE_MENU: "/admin/updateMenu",
  DELETE_MENU: "/admin/deleteMenu",
  GET_ROLE_MENUS: "/admin/getRoleMenus",
  ADD_ROLE_MENUS: "/admin/addRoleMenus",
  UPDATE_ROLE_MENUS: "/admin/updateRoleMenus",
  DELETE_ROLE_MENUS: "/admin/deleteRoleMenus",
  GET_SUPER_DISTRIBUTORS: "/admin/getSuperDistributors",
  GET_DISTRIBUTORS: "/admin/getDistributors",
  GET_AGENTS: "/admin/getAgents",
  GET_OPERATORS: "/admin/getOperators",
  CREATE_AFFILIATE: "/admin/createAffiliate",
  UPDATE_AFFILIATE: "/admin/updateAffiliate",
  IS_USER_NAME_AVAILABLE: "/admin/isUserNameAvailable",
  CREATE_SUB_ADMIN: "/admin/createSubAdmin",
  UPDATE_SUB_ADMIN: "/admin/updateSubAdmin",
  GET_SUB_ADMINS: "/admin/getSubAdmins",
  GET_SUB_ADMIN: "/admin/getSubAdmin",
  SET_FLAG: "/admin/setFlag",
  GET_SET_FLAG_PLAYER: "/admin/getSetFlagPlayer",
  GET_GAMES: "/casinoGame/getCasinoGames",
  GET_GAME: "/casinoGame/getCasinoGameById",
  GET_GAME_NAME: "/casinoGame/getCasinoGameByName",
  CREATE_GAME: "/casinoGame/createCasinoGame",
  DELETE_GAME: "/casinoGame/deleteCasinoGame",
  UPDATE_GAME: "/casinoGame/updateCasinoGame",

  ADJUST_POINTS: "/transaction/adjustPoints",
  USER_LIST: "/admin/userList",

  GET_USER_LIST_BY_ROLE_ID: "/admin/getNamesForRole",
  CHANGE_PASSWORD: "/admin/changePwd",
  GET_USER_DETAILS_BY_USER_ID: "/admin/getChildAndParentDetails",

  GET_GAME_REPORTS: "/admin/getGameReports",
  GET_GAME_REPORTS_SUMMARY: "/admin/getGameReportsSummary",
  GET_TRANSACTIONS: "/transaction/getTransactions",
  BAN_USER: "/admin/banUser",
  UN_BAN_USER: "/admin/unBanUser",
  SUSPEND_USER: "/admin/suspendUser",
  UN_SUSPEND_USER: "/admin/unSuspendUser",

  GET_ONLINE_PLAYERS: "/admin/getOnlinePlayers",
  GET_ONLINE_PLAYERS_BY_ROOM_WISE: "/admin/getOnlinePlayersByRoomWise",
  GET_USER_INFO_BY_ACTIVITY: "/admin/getUserInfoByActivity",

  GET_TURN_OVER_REPORTS: "/admin/getTurnoverReport",
  GET_LOGGED_USER_DETAILS: "/user/getLoggedUserDetails",
};
