import { Box, Typography } from "@mui/material";
import moment from "moment-mini";
import React, { memo, useEffect, useState } from "react";

const HeaderClock = () => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Box>
        <Typography variant="h6" color="primary">
          {moment(date).format("MMM D YYYY  HH:mm:ss")}
        </Typography>
      </Box>
    </div>
  );
};

export default memo(HeaderClock);
