// hooks
import Avatar from "./Avatar";
import useAuth from "../hooks/useAuth";
// utils
import createAvatar from "../utils/createAvatar";
//

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.userName}
      color={user?.photoURL ? "default" : createAvatar(user?.userName).color}
      {...other}
    >
      {createAvatar(user?.userName).name}
    </Avatar>
  );
}
