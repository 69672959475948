import { Collapse, List } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavItemRoot, NavItemSub } from "./NavItem";
import { getActive } from "..";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse }) {
  const { pathname } = useLocation();
  const active = list.path === PATH_DASHBOARD.root
    ? pathname === PATH_DASHBOARD.general.home
    : getActive(list.path, pathname);
  
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  return (
    <>
      <NavItemRoot
        item={list}
        isCollapse={isCollapse}
        active={active}
        open={open}
        onOpen={() => setOpen(!open)}
      />

      {!isCollapse && hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 2 }}>
            {(list.children || []).map((item) => (
              <NavListSub key={item.title} list={item} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list }) {
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  return (
    <>
      <NavItemSub
        item={list}
        onOpen={() => setOpen(!open)}
        open={open}
        active={active}
      />

      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub
                key={item.title}
                item={item}
                active={getActive(item.path, pathname)}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
