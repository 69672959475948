import { Dialog, Slide } from "@mui/material";
import PropTypes from "prop-types";

// @mui
//
import { forwardRef } from "react";

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default function DialogAnimate({
  open = false,
  onClose,
  children,
  ...other
}) {
  return (
    open && (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        {...other}
      >
        {children}
      </Dialog>
    )
  );
}
