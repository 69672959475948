import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
// utils
import { setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  menus: [],
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, menus } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      menus,
    };
  },
  LOGIN: (state, action) => {
    const { user, menus } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      menus,
    };
  },
  UPDATE_BALANCE: (state, action) => {
    const { balance } = action.payload;
    return {
      ...state,
      user: { ...state.user, realChips: state?.user?.realChips + balance },
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserBalance: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        let accessUser = window.localStorage.getItem("authUser")
          ? JSON.parse(window.localStorage.getItem("authUser"))
          : null;

        if (accessToken && accessUser) {
          setSession(accessToken);
          const { user, menus } = accessUser;
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
              menus,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (payload) => {
    const { sid: accessToken, menus, user, ...rest } = payload;
    const userObj = { ...user, userId: user?.id, ...rest };
    setSession(accessToken);
    dispatch({
      type: "LOGIN",
      payload: {
        user: userObj,
        menus,
      },
    });
    localStorage.setItem("authUser", JSON.stringify({ menus, user: userObj }));
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem("authUser");
    localStorage.removeItem("userChips");
    dispatch({ type: "LOGOUT" });
  };

  const updateUserBalance = async (balance) => {
    try {
      dispatch({
        type: "UPDATE_BALANCE",
        payload: {
          balance,
        },
      });
    } catch (error) {}
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        updateUserBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
