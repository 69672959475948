// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 2, // Adjusted for more pronounced shadow
      },

      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderColor: theme.palette.grey[500_12],
            borderWidth: "1px",
            borderStyle: "solid",
          },
        },
      ],

      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          transition: "all 0.3s ease",
          // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          // boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 8px",
          // boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
          boxShadow: `${theme.palette.primary.light} 0px 0px 0px 1px inset`,
          // boxShadow: "rgb(33, 43, 54) 1px 1px 10px 2px",
          "&:hover": {
            // border:"green 1px solid",
            // boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 12px",
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    },
  };
}
