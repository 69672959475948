import { Suspense, lazy, useMemo } from "react";
import { Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
// layouts
import { PATH_AUTH } from "./paths";
import LoadingScreen from "../components/LoadingScreen";
import { PATH_AFTER_LOGIN } from "../config";
import {
  INACTIVE_USER_RESTRICTED_ROUTES,
  USER_STATUS_KEYWORDS,
} from "../constants/metaData";
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";

import useAuth from "../hooks/useAuth";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import { convertMenuToItems, getPathAfterLogin } from "../utils/helper";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { menus, isAuthenticated, user } = useAuth();
  const isInActiveUser = user?.status?.status === USER_STATUS_KEYWORDS.INACTIVE;

  const convertedMenu = useMemo(
    () =>
      convertMenuToItems(
        isInActiveUser
          ? menus?.filter(({ id }) =>
              INACTIVE_USER_RESTRICTED_ROUTES.includes(id) ? false : true
            )
          : menus || []
      ) || [],
    [isInActiveUser, menus, convertMenuToItems]
  );

  const PATH_AFTER_LOGIN_NEW =
    isAuthenticated && convertedMenu && convertedMenu.length
      ? getPathAfterLogin(convertedMenu)
      : PATH_AFTER_LOGIN;

  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN_NEW} replace />,
          index: true,
        },
        { path: "home", element: <Home /> },

        {
          path: "accountBook",
          element: <Outlet />,
          children: [
            { index: true, element: <AdjustPoints /> },
            { path: "adjustPoints", element: <AdjustPoints /> },
            { path: "changePassword", element: <ChangePassword /> },
          ],
        },
        {
          path: "player",
          element: <Outlet />,
          children: [
            { index: true, element: <PlayerSearch /> },
            { path: "playerSearch", element: <PlayerSearch /> },
          ],
        },
        {
          path: "affiliate",
          element: <Outlet />,
          children: [
            { index: true, element: <CreatePlayer /> },
            { path: "agent", element: <AgentManagement /> },
            {
              path: "distributor",
              element: <DistributorManagement />,
            },
            {
              path: "superDistributor",
              element: <SuperDistributorManagement />,
            },
            {
              path: "operator",
              element: <OperatorManagement />,
            },

            { path: "subAdmins", element: <SubAdmins /> },
            { path: "createPlayer", element: <CreatePlayer /> },
          ],
        },
        {
          path: "reports",
          element: <Outlet />,
          children: [
            { index: true, element: <GameHistory /> },
            { path: "gameReports/gameHistory", element: <GameHistory /> },
            { path: "transactionReports/chipsFile", element: <PointFile /> },
            { path: "turnoverReports", element: <TurnOverReport /> },
            {
              path: "gameWiseTurnoverReports",
              element: <GameWiseTurnOverReport />,
            },
          ],
        },
        {
          path: "menu",
          element: <Outlet />,
          children: [
            { index: true, element: <MainMenu /> },
            { path: "mainMenu", element: <MainMenu /> },
            { path: "roleBaseMenu", element: <RoleBasedMenu /> },
          ],
        },
        {
          path: "games",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate to="/dashboard/games/gameManagement" replace />
              ),
            },
            { path: "gameManagement", element: <GamesManagement /> },
          ],
        },
        {
          path: "playerTreatment",
          element: <PlayerTreatment />,
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// DASHBOARD

const Home = Loadable(lazy(() => import("../pages/dashboard/Home")));

// Account Book

const AdjustPoints = Loadable(
  lazy(() => import("../pages/dashboard/accountBook/AdjustPoints"))
);

const ChangePassword = Loadable(
  lazy(() => import("../pages/dashboard/accountBook/ChangePassword"))
);

// Player

const PlayerSearch = Loadable(
  lazy(() => import("../pages/dashboard/player/PlayerSearch"))
);

// Affiliate

const AgentManagement = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/AgentManagement"))
);
const DistributorManagement = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/DistributorManagement"))
);
const SuperDistributorManagement = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/SuperDistributorManagement"))
);
const OperatorManagement = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/OperatorManagement"))
);
const SubAdmins = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/SubAdmins"))
);
const CreatePlayer = Loadable(
  lazy(() => import("../pages/dashboard/affiliate/CreatePlayer"))
);

// Reports

const GameHistory = Loadable(
  lazy(() => import("../pages/dashboard/reports/GameReports/GameHistory"))
);
const PointFile = Loadable(
  lazy(() => import("../pages/dashboard/reports/TransactionReport/PointFile"))
);
const TurnOverReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/TurnOverReports"))
);
const GameWiseTurnOverReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/GameWiseTurnOverReports"))
);

// Menu
const MainMenu = Loadable(
  lazy(() => import("../pages/dashboard/menu/MainMenu"))
);
const RoleBasedMenu = Loadable(
  lazy(() => import("../pages/dashboard/menu/RoleBasedMenu"))
);

// MAIN

const NotFound = Loadable(lazy(() => import("../pages/Page404")));

// GAMES

const GamesManagement = Loadable(
  lazy(() => import("../pages/dashboard/games/GamesManagement"))
);
const PlayerTreatment = Loadable(
  lazy(() => import("../pages/dashboard/playerTreatment/SetFlag"))
);
