import { CircularProgress, Container } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <Container
      disableGutters
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "20px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CircularProgress color="primary" size={40} thickness={6} />
    </Container>
  );
};

export default Loader;