import { camelCase, forEach, groupBy, map } from "lodash";
import moment from "moment-mini";
import SvgIconStyle from "../components/SvgIconStyle";
import { PATH_AFTER_LOGIN } from "../config";
import {
  allSearchByItems,
  distributorRestrictions,
  distributorTypes,
  rolePermissions,
} from "../constants/metaData";
export const getDateRange = (rangeType) => {
  switch (rangeType) {
    case 1:
      return {
        startDate: moment().startOf("date").toDate(),
        endDate: moment().endOf("date").toDate(),
      };
    case -1:
      return {
        startDate: moment().subtract(1, "day").startOf("date").toDate(),
        endDate: moment().startOf("date").toDate(),
      };
    case -6:
      return {
        startDate: moment().startOf("isoWeek").startOf("day").toDate(),
        endDate: moment().endOf("date").toDate(),
      };
    case -7:
      return {
        startDate: moment()
          .startOf("isoWeek")
          .subtract(1, "week")
          .startOf("day")
          .toDate(),
        endDate: moment()
          .startOf("isoWeek")
          .subtract(1, "day")
          .endOf("day")
          .toDate(),
      };
    case -29:
      return {
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate(),
      };
    case -30:
      return {
        startDate: moment().subtract(1, "month").startOf("month").toDate(),
        endDate: moment().subtract(1, "month").endOf("month").toDate(),
      };
    default:
      return null;
  }
};

export const getAllPermissionsByRole = (role, allPermissions) => {
  if (allPermissions && allPermissions.length > 0) {
    let updatedPermissions = [...allPermissions];
    switch (role) {
      case distributorTypes.NORMAL:
        updatedPermissions = updatedPermissions.map((item) =>
          item.id === 4
            ? {
                ...item,
                types: item.types.filter(({ name }) =>
                  distributorRestrictions.includes(name) ? false : true
                ),
              }
            : item
        );
        break;
      case distributorTypes.AGENT:
        updatedPermissions = updatedPermissions.filter((item) => item.id !== 4);
        break;
      default:
        break;
    }
    return updatedPermissions;
  }
  return [];
};

export const getParsedTreeData = (treeData) => {
  const groupedByParent = groupBy(treeData, "parent");
  const outputArray = [];

  forEach(groupedByParent[null], (parent) => {
    const children = map(groupedByParent[parent.id], (child) => ({
      id: child.id * 10 + 1, // Adjust the ID generation logic as needed
      label: child.name,
      parentId: parent.id,
    }));

    outputArray.push({
      id: parent.id,
      label: parent.name,
      children,
    });
  });
  return outputArray;
};

export function convertData(treeData) {
  // Group treeData by parent ID using lodash's groupBy
  const groupedData = groupBy(treeData, (item) =>
    item.parent ? item.parent.id : null
  );

  // Create a function to recursively build the hierarchy
  function buildHierarchy(id) {
    return map(groupedData[id], (item) => ({
      id: item.id,
      label: item.name,
      isPage: item.isPage,
      children: buildHierarchy(item.id), // Recursively build children
      parent: item.parent,
    }));
  }

  // Start with the top-level items (parent ID is null)
  const outputArray = buildHierarchy(null);

  return outputArray;
}

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  player: getIcon("ic_user"),
  accountBook: getIcon("ic_banking"),
  dashboard: getIcon("ic_home"),
  reports: getIcon("ic_reports"),
  affiliate: getIcon("ic_ecommerce"),
  menu: getIcon("ic_kanban"),
  games: getIcon("ic_games"),
};

export const convertMenuToItems = (menuArr) => {
  // A helper function that finds the parent of a given menu object
  function findParent(menu) {
    // Find the menu object that has the same name as the menu's parent name
    let parent = menuArr?.find((m) => m?.name === menu?.parent?.name);

    // If no parent is found, return null
    if (!parent) {
      return null;
    }

    // Otherwise, return the parent object
    return parent;
  }

  // A helper function that finds the children of a given menu object
  function findChildren(menu) {
    // Filter the menuArr by the parent property
    return menuArr?.filter((m) => m.parent && m.parent.name === menu.name);
  }

  // A helper function that converts a menu object into an item object
  function convertMenuToItem(menu) {
    // Create a new item object with the title property
    let item = {
      title: menu.name,
    };

    // Find the parent of the menu object
    let parent = findParent(menu);

    // If there is a parent, find its parent as well
    let grandParent = parent ? findParent(parent) : null;

    // If there is a grandParent, use its name, parent's name and menu's name to form the path
    // If there is only a parent, use its name and menu's name to form the path
    // If there is no parent, use only the menu's name to form the path
    item.path = grandParent
      ? `/dashboard/${camelCase(grandParent.name)}/${camelCase(
          parent.name
        )}/${camelCase(menu.name)}`
      : parent
      ? `/dashboard/${camelCase(parent.name)}/${camelCase(menu.name)}`
      : menu.isPage
      ? `/dashboard`
      : `/dashboard/${camelCase(menu.name)}`;

    item.icon =
      !parent && ICONS[camelCase(menu?.name)]
        ? ICONS[camelCase(menu?.name)]
        : null;
    // Find the children of the menu object
    let children = findChildren(menu);

    // If there are any children, recursively convert them into items and add them to the item object
    if (children.length > 0) {
      item.children = children.map(convertMenuToItem);
    }

    // Return the item object
    return item;
  }

  // Filter the menuArr by the parent property being null

  let rootMenus = menuArr?.filter((m) => m.parent === null);

  // Convert each root menu into an item and return the array of items
  return rootMenus.map(convertMenuToItem);
};

export const getAllChildrenIds = (item) => {
  let childrenIds = [];
  if (item.children && item.children.length > 0) {
    for (const child of item.children) {
      childrenIds.push(child.id);
      childrenIds = childrenIds.concat(getAllChildrenIds(child));
    }
  }
  return childrenIds;
};

export const getOperatorIdFromUser = (user) => {
  let operatorId;
  if (
    user &&
    user?.operator &&
    user?.roleMask &&
    (user?.roleMask?.roleId !== rolePermissions.OPERATOR ||
      user?.roleMask?.roleId !== rolePermissions.ADMIN)
  ) {
    operatorId = user?.operator?.id;
  } else {
    operatorId = user?.id;
  }
  return operatorId;
};

export const getPathAfterLogin = (pathData) => {
  let path = "";
  if (pathData?.[0]?.path === "/dashboard") {
    path = PATH_AFTER_LOGIN;
  } else {
    path = pathData?.[0]?.path;
  }
  return path;
};

export const checkRoleById = (user, targetRole) =>
  targetRole === user?.roleMask?.roleId;

export const gameListMapper = (gameListObj) =>
  Object.keys(gameListObj)?.length > 0
    ? Object.keys(gameListObj).map((key) => ({
        gameName: gameListObj[key],
        id: +key,
      }))
    : [];

export const getPartnerTypesByUser = (user, isIncludeSelf = false) => {
  let types = [];
  if ([rolePermissions.ADMIN].includes(user?.roleMask?.roleId)) {
    types = allSearchByItems;
  } else if ([rolePermissions.OPERATOR].includes(user?.roleMask?.roleId)) {
    types = allSearchByItems.filter(
      (item) => item.value !== rolePermissions.OPERATOR
    );
  } else if (
    [rolePermissions.SUPER_DISTRIBUTOR].includes(user?.roleMask?.roleId)
  ) {
    types = allSearchByItems.filter(
      (item) =>
        ![rolePermissions.SUPER_DISTRIBUTOR, rolePermissions.OPERATOR].includes(
          item.value
        )
    );
  } else if ([rolePermissions.DISTRIBUTOR].includes(user?.roleMask?.roleId)) {
    types = allSearchByItems.filter(
      (item) =>
        ![
          rolePermissions.SUPER_DISTRIBUTOR,
          rolePermissions.OPERATOR,
          rolePermissions.DISTRIBUTOR,
        ].includes(item.value)
    );
  } else if ([rolePermissions.AGENT].includes(user?.roleMask?.roleId)) {
    types = allSearchByItems.filter(
      (item) =>
        ![
          rolePermissions.SUPER_DISTRIBUTOR,
          rolePermissions.OPERATOR,
          rolePermissions.DISTRIBUTOR,
          rolePermissions.AGENT,
        ].includes(item.value)
    );
  } else {
    types = allSearchByItems;
  }
  if (!isIncludeSelf) {
    types = types.filter((item) => item.value !== 0);
  }
  return types;
};

export const getAllChildrenFromParent = (data, parentId) => {
  const children = [];

  function findChildren(node) {
    if (node.id === parentId) {
      children.push(...node.children);
    } else if (node.children && node.children.length > 0) {
      node.children.forEach(findChildren);
    }
  }

  data.forEach(findChildren);

  return children;
};

export const getLabelFromValue = (arr, key) =>
  arr.length > 0 ? arr.find(({ value }) => value === key)?.label || key : key;

export const sessionLogout = async () => {
  window.localStorage.clear();
  let currentUrl = window.location.origin;
  window.location.href = `${currentUrl}/login`;
};

export const getSubRoleId = (roleId) => {
  let subId = roleId;
  switch (roleId) {
    case 1:
      subId = 1;
      break;
    case 2:
    case 3:
    case 4:
    case 5:
      subId = subId + 1;
      break;
    case 6:
      subId = 1;
      break;
    default:
      break;
  }
  return subId;
};

export const getParentIdOfUser = (user) => {
  if (!user) return null;
  switch (user?.roleId) {
    case rolePermissions.SUPER_DISTRIBUTOR:
      return user?.operatorId;
    case rolePermissions.DISTRIBUTOR:
      return user?.superDistributorId;
    case rolePermissions.AGENT:
      return user?.distributorId;
    case rolePermissions.PLAYER:
      return user?.agentId;
    default:
      return null;
  }
};
