import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { ICON, NAVBAR } from "../../../config";

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "activeRoot" && prop !== "activeSub" && prop !== "subItem",
})(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s, transform 0.2s, box-shadow 0.3s",
  boxShadow: `0 2px 5px ${alpha(theme.palette.common.black, 0.1)}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  border:"1px outset snow",
  // Hover effect
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    transform: "scale(1.03)",
    boxShadow: `0 4px 10px ${alpha(theme.palette.common.black, 0.2)}`,
    cursor: "pointer",
  },
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.2)}`,
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "isCollapse",
})(({ isCollapse, theme }) => ({
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["width", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
  fontWeight: isCollapse ? "normal" : "bold",
  letterSpacing: "0.5px",
  color: theme.palette.text.primary,
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": { width: "100%", height: "100%", transition: "transform 0.2s",fill: "currentColor" },
  "&:hover svg": { transform: "scale(1.1)", },
});