import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
  <defs>
    <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
      <stop offset="0%" stopColor={PRIMARY_DARK} />
      <stop offset="100%" stopColor={PRIMARY_MAIN} />
    </linearGradient>
    <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset="0%" stopColor={PRIMARY_LIGHT} />
      <stop offset="100%" stopColor={PRIMARY_MAIN} />
    </linearGradient>
    <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset="0%" stopColor={PRIMARY_LIGHT} />
      <stop offset="100%" stopColor={PRIMARY_MAIN} />
    </linearGradient>
  </defs>
  <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
    <path
      fill="url(#BG1)"
      d="M256 0L512 256L256 512L0 256Z"
    />
    <path
      fill="url(#BG2)"
      d="M256 256L512 384L256 512L0 384Z"
    />
    <path
      fill="url(#BG3)"
      d="M256 256L256 512L256 384L256 256Z"
    />
  </g>
</svg>
      {/* <svg viewBox="0 0 100 100">
        <rect x="0" y="0" width="100" height="100" fill="#ffffff" />
        <path d="M50,25 L25,75 L75,75 Z" fill={PRIMARY_MAIN} />
      </svg> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
